<template>
  <v-sheet class="pa-5" elevation="1">
    <div class="mx-5 d-flex justify-space-between">
      <h2 class="text-center">Macro planning</h2>
      <v-btn color="grey" text @click="macroDialog = !macroDialog">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <div class="ml-2" v-if="van.macroPlanning.length == 0">
      <v-subheader class="error--text">No Macro Planning is Set</v-subheader>
    </div>
    <div class="mx-5" v-else>
      <v-row no-gutters>
        <v-col cols="3"></v-col>
        <v-col cols="3">
          <v-subheader class="success--text">ON</v-subheader>
        </v-col>
        <v-col cols="3">
          <v-subheader class="secondary--text">Driver</v-subheader>
        </v-col>
      </v-row>
      <v-row v-for="(item, i) in van.macroPlanning" :key="i">
        <v-col cols="3">{{ returnWeekDay(i) }}</v-col>
        <v-col cols="3">{{ item.time }}</v-col>
        <v-col cols="3">{{ item.driver }}</v-col>
      </v-row>
    </div>
    <v-dialog v-model="macroDialog" persistent scrollable>
      <macro-planning-form :toggle="toggleMacro" :van="van" />
    </v-dialog>
  </v-sheet>
</template>

<script>
import MacroPlanningForm from './MacroPlanningForm.vue';
export default {
  components: { MacroPlanningForm },

  props: {
    van: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    macroDialog: false,
    macroplanning1: [
      {
        id: 1,
        day: "Lundi",
        on: {
          start: '06h00',
          end: '23h59'
        },
        off: {
          start: '',
          end: ''
        },
        driver: "Mamadou Diouf",
      },
      {
        id: 2,
        day: "Mardi",
        on: {
          start: '06h00',
          end: '23h59'
        },
        off: {
          start: '',
          end: ''
        },
        driver: "Mamadou Diouf",
      },
      {
        id: 3,
        day: "Mercredi",
        on: {
          start: '06h00',
          end: '23h59'
        },
        off: {
          start: '',
          end: ''
        },
        driver: "Mamadou Diouf",
      },
      {
        id: 4,
        day: "Jeudi",
        on: {
          start: '06h00',
          end: '23h59'
        },
        off: {
          start: '',
          end: ''
        },
        driver: "Mamadou Diouf",
      },
      {
        id: 5,
        day: "Vendredi",
        on: {
          start: '06h00',
          end: '23h59'
        },
        off: {
          start: '',
          end: ''
        },
        driver: "Mamadou Diouf",
      },
      {
        id: 6,
        day: "Samedi",
        on: {
          start: '06h00',
          end: '15h59'
        },
        off: {
          start: '18h00',
          end: '22h59'
        },
        driver: "Mamadou Diouf",
      },
      {
        id: 7,
        day: "Dimanche",
        on: {
          start: '',
          end: ''
        },
        off: {
          start: '',
          end: ''
        },
        driver: "Mamadou Diouf",
      },
    ],
    vanMacro: []
  }),

  created() {
    // let objectedMacro = Object.fromEntries(this.van.macroPlanning.values())

    // for(const [key, value] of Object.entries(objectedMacro)) {
    //   this.vanMacro.push(value)
    // }

      console.log(this.van.macroPlanning)
  },

  methods: {
    toggleMacro(macroPlanning) {
      this.macroDialog = !this.macroDialog
      this.van.macroPlanning = macroPlanning
    },

    processTime(time) {
      if(time.start == '') return '-'
      return `${time.start} - ${time.end}`
    },

    returnWeekDay(number) {
      if(number == 0) return 'dimanche'
      if(number == 1) return 'lundi'
      if(number == 2) return 'mardi'
      if(number == 3) return 'mercredi'
      if(number == 4) return 'jeudi'
      if(number == 5) return 'vendredi'
      if(number == 6) return 'samedi'
      return 'N/A'
    }
  }

};
</script>

<style></style>
