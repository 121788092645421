<template>
  <v-card color="" elevation="0" outlined class="pa-0">
    <div class="card-header">
      <v-card-title class="d-flex justify-space-between pa-5">
        <div class="white--text headline">Van Macro Planning</div>
        <v-icon color="white" class="d-none d-sm-flex" @click="toggle()"
          >mdi-close-thick</v-icon
        >
      </v-card-title>
    </div>
    <v-divider />
    <v-card-text style="height: auto">
      <v-card elevation="0">
        <v-card-text class="pt-5 pb-0">
          <h2 class="headline error--text text-center">
            Valid Time Range: 00:00 - 23:59
          </h2>
          <v-row no-gutters>
            <v-col cols="3"></v-col>
            <v-col cols="3">
              <v-subheader class="success--text">ON</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-subheader class="secondary--text">Driver</v-subheader>
            </v-col>
          </v-row>
          <v-row v-for="item in macroPlanning" :key="item.id">
            <v-col cols="3">{{ item.day }}</v-col>
            <v-col cols="5">
              <v-row no-gutters>
                <v-col cols="6" class="pr-5">
                  <v-text-field
                    dense
                    outlined
                    v-model="item.start"
                    v-mask="['##:##']"
                    label="Start Time"
                    @change="validateStartTime(item.start, item.id)"
                    :error-messages="item.startErr"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pr-5">
                  <v-text-field
                    dense
                    outlined
                    label="End Time"
                    v-model="item.end"
                    v-mask="['##:##']"
                    @change="validateEndTime(item.end, item.id)"
                    :error-messages="item.endErr"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="driversArray"
                v-model="item.driver"
                item-text="fullNameWithId"
                return-object
                outlined
                dense
                label="Driver"
                @change="addingDriver(item.driver, item.id)"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row no-gutters justify-sm="space-between">
        <v-col cols="12" sm="auto" order="2" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="grey"
            class="mt-5 mt-sm-0"
            elevation="0"
            dark
            @click="toggle()"
            >Cancel
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto" order="1" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="primary"
            elevation="0"
            dark
            :loading="loader"
            @click="saving()"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import * as fb from "../../firebase";
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween.js'
dayjs.extend(isBetween)

export default {
  directives: { mask },

  props: {
    toggle: {
      type: Function,
      required: false,
    },

    van: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loader: false,
    driversArray: [],
    macroPlanning: [
      {
        id: 0,
        day: "dimanche",
        start: "",
        end: "",
        driver: "",
        driverId: "",
        startstartErr: "",
        endErr: "",
      },
      {
        id: 1,
        day: "lundi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
        startErr: "",
        endErr: "",
      },
      {
        id: 2,
        day: "mardi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
        startErr: "",
        endErr: "",
      },
      {
        id: 3,
        day: "mercredi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
        startErr: "",
        endErr: "",
      },
      {
        id: 4,
        day: "jeudi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
        startErr: "",
        endErr: "",
      },
      {
        id: 5,
        day: "vendredi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
        startErr: "",
        endErr: "",
      },
      {
        id: 6,
        day: "samedi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
        startErr: "",
        endErr: "",
      },
    ],
    macroMap: new Map(),
    driversToUpdate: [],
  }),

  async created() {
    this.driversArray = await this.$store.dispatch("driver/getDrivers");

    for (let x = 0; x < this.macroPlanning.length; x++) {
      this.macroPlanning[x].start =
        this.van.macroPlanning[x].time.split("-")[0];
      this.macroPlanning[x].end = this.van.macroPlanning[x].time.split("-")[1];

      this.macroPlanning[x].driver = this.van.macroPlanning[x].driver;
    }
    // if(this.van.macroPlanning.length > 0) {
    //   this.macroPlanning = this.van.macroPlanning
    // }
  },

  methods: {
    validateStartTime(event, index) {
      if(dayjs(`2020-10-25 ${event}`).isBetween('2020-10-25 00:00', dayjs('2020-10-25 23:59'), 'second', '[]')) this.macroPlanning[index].startErr = ""
      else return this.macroPlanning[index].startErr = "Please enter the valid time range"
    },

    validateEndTime(event, index) {
      if(dayjs(`2020-10-25 ${event}`).isBetween('2020-10-25 00:00', dayjs('2020-10-25 23:59'), 'second', '[]')) this.macroPlanning[index].endErr = ""
      else return this.macroPlanning[index].endErr = "Please enter the valid time range"

      if(dayjs(`2020-10-25 ${event}`).isBetween(`2020-10-25 ${this.macroPlanning[index].start}`, dayjs('2020-10-25 23:59'), 'second', '[]')) this.macroPlanning[index].endErr = ""
      else return this.macroPlanning[index].endErr = "End time must be greater than start time"
    },

    async addingDriver(payload, index) {
      this.macroPlanning[index].driver = payload.fullNameWithId;
      this.macroPlanning[index].driverId = payload.id;
    },

    async saving() {
      this.macroPlanning.forEach((item) => {
        this.macroMap.set(item.id, {
          time: item.start ? `${item.start}-${item.end}` : "",
          driver: item.driver,
          driverId: item.driverId,
        });
      });

      let objectedMap = Object.fromEntries(this.macroMap);

      let driversIdArray = [];

      for (const [key, value] of Object.entries(objectedMap)) {
        driversIdArray.push(value.driverId);
      }

      let filteredDriversArray = [
        ...new Set(driversIdArray.map((x) => x)),
      ].filter((v) => v);

      // TODO: Update assignedVans of all the drivers

      filteredDriversArray.forEach(async (item) => {
        let driverToUpdate = this.driversArray.find((el) => el.id === item);
        driverToUpdate.assignedVans.push(this.van.customId);
        await this.$store.dispatch("driver/addVan", {
          id: driverToUpdate.id,
          assignedVans: driverToUpdate.assignedVans,
        });
      });

      // TODO: Update Macroplanning for van

      const van = await fb.db
        .doc(`van/${this.van.id}`)
        .update({ macroPlanning: objectedMap });

      this.toggle(objectedMap);
    },

    async addDriver(item) {
      let driverId = item.split("( ")[1].split(" )")[0];

      if (!this.van.assignedDrivers.includes(driverId)) {
        this.van.assignedDrivers.push(driverId);
        this.$store
          .dispatch("driver/getDriverByCustomId", { customId: driverId })
          .then((driver) => {
            if (!driver.assignedVans.includes(this.van.customId)) {
              driver.assignedVans.push(this.van.customId);
              this.driversToUpdate.push(driver);
            }
          });
      }
    },

    async saveMacro() {
      this.loader = true;

      console.log(this.van.id, this.macroPlanning)

      // await this.$store.dispatch("van/setMacroPlanning", {
      //   id: this.van.id,
      //   macroPlanning: this.macroPlanning,
      //   assignedDrivers: this.van.assignedDrivers,
      // });

      // this.driversToUpdate.forEach(async (obj) => {
      //   await this.$store.dispatch("driver/addVan", {
      //     id: obj.id,
      //     assignedVans: obj.assignedVans,
      //   });
      // });

      // this.van.macroPlanning = this.macroPlanning;

      this.loader = false;

      this.toggle();
    },
  },
};
</script>

<style></style>
